import { 
  FILTER_MANUFACTURER, 
  FILTER_MANUFACTURER_PROM, 
  FILTER_CATEGORIES, 
  FILTER_DELIVERY, 
  FILTER_CLEAN, 
  FILTER_CLEAN_CATEGORY, 
  SORT_BY, 
  REFRESH_STORE 
} from '../constants/action-types'
// import { persistReducer } from "redux-persist";
// import storage from 'redux-persist/lib/storage';

const initialState = {
  category: "",
  manufacturer: [],
  manufacturerProm: [],
  sortBy: "",
  delivery: ""
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_MANUFACTURER:
      if (state.manufacturer.indexOf(action.name) >= 0) {
        return {
          ...state,
          manufacturer: state.manufacturer.filter(item => item !== action.name)
        }
      }
      return {
        ...state,
        manufacturer: [...state.manufacturer, action.name]
      };

    case FILTER_MANUFACTURER_PROM:
      if (state.manufacturerProm.indexOf(action.manufacturerProm) >= 0) {
        return {
          ...state,
          manufacturerProm: state.manufacturerProm.filter(item => item !== action.manufacturerProm)
        }
      }
      return {
        ...state,
        manufacturerProm: [...state.manufacturerProm, action.manufacturerProm]
      };

    case FILTER_CATEGORIES:
      return {
        ...state,
        category: action.category
      };

    case FILTER_DELIVERY:
      if (state.delivery === action.delivery) {
        return {
          ...state,
          delivery: ""
        }
      }
      return {
        ...state,
        delivery: action.delivery
      };

    case SORT_BY:
      return {
        ...state,
        sortBy: action.sortBy
      };

    case FILTER_CLEAN:
      return {
        ...state,
        manufacturer: [],
        manufacturerProm: [],
        delivery: false,
      };

    case FILTER_CLEAN_CATEGORY:
      return {
        ...state,
        category: ""
      };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
}

// const persistConfig = {
//   keyPrefix: "buduj-",
//   key: "filters",
//   storage
// }

export default filtersReducer;