import {
  RECEIVE_BRANCHES,
  REFRESH_STORE
} from '../constants/action-types';

const initialState = {
  branches: []
}

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BRANCHES:
      return { ...state, branches: action.branches };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default branchesReducer;