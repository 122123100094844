import {
  ADD_TO_CART,
  CHANGE_QTY,
  REMOVE_FROM_CART,
  SHOW_CART_MODAL,
  HIDE_CART_MODAL,
  CLEAR_CART,
  SEND_CLEAR_CART,
  DECREMENT_QTY,
  GET_DELIVERY_COST,
  FILTER_CART,
  REFRESH_STORE
} from '../constants/action-types';
import { findProductVariantIndex, findProductIndex } from '../utils';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

let initialState = {
  cart: [],
  deliveryCost: null,
  modalProduct: '',
  showModal: false
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      if (findProductVariantIndex(state.cart, action.product.product_variant_id) && findProductIndex(state.cart, action.product.product_id)) {
        const cart = state.cart.reduce((acc, product) => {
          if (product.product_variant_id === action.product.product_variant_id && product.product_id === action.product.product_id) {
            acc.push(
              {
                ...product,
                qty: Number(product.qty) + action.qty,
                sum: (action.product.prices.promo_on ? action.product.prices.promo_price : action.product.prices.promo_price) * (product.qty + action.qty)
              });
          } else {
            acc.push(product);
          }
          return acc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: (action.product.prices.promo_on ? action.product.prices.promo_price : action.product.prices.promo_price) * action.qty
          }
        ]
      };

    case CHANGE_QTY: 
      if (findProductVariantIndex(state.cart, action.product.product_variant_id) && findProductIndex(state.cart, action.product.product_id)) {
        const cart = state.cart.reduce((acc, product) => {
          if (product.product_variant_id === action.product.product_variant_id && product.product_id === action.product.product_id) {
            acc.push(
              {
                ...product,
                qty: action.qty,
                sum: (action.product.prices.promo_on ? action.product.prices.promo_price : action.product.prices.promo_price) * action.qty
              });
          } else {
            acc.push(product);
          }
          return acc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: (action.product.prices.promo_on ? action.product.prices.promo_price : action.product.prices.promo_price) * action.qty
          }
        ]
      }

    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter(item => item.product_variant_id !== action.product.product_variant_id || item.product_id !== action.product.product_id)
      };

    case CLEAR_CART:
      return {
        ...state,
        cart: []
      };

    case SEND_CLEAR_CART:
      return {
        ...state,
        cart: []
      };

    case DECREMENT_QTY:
      const cart = state.cart.reduce((acc, product) => {
        if (product.product_variant_id === action.product.product_variant_id && product.product_id === action.product.product_id) {
          acc.push(
            {
              ...product,
              qty: product.qty - 1,
              sum: (action.product.prices.promo_on ? action.product.prices.promo_price : action.product.prices.promo_price) * (product.qty - 1)
            });
        } else {
          acc.push(product);
        }
        return acc;
      }, []);

      return { ...state, cart };

    case SHOW_CART_MODAL:
      return {
        ...state,
        showModal: true,
        modalProduct: action.product
      }

    case HIDE_CART_MODAL:
      return {
        ...state,
        showModal: false
      }

    case GET_DELIVERY_COST:
      return { ...state, deliveryCost: action.deliveryCost };

    case FILTER_CART: 
      const {archedEdges, fences1, fences2} = action.id;
      
      return {
        ...state,
        cart: state.cart.filter(item => 
          item.product_id === archedEdges || 
          item.product_id === fences1 || 
          item.product_id === fences2
        )
      };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: "buduj-",
  key: "cartlist",
  storage
}

export default persistReducer(persistConfig, cartReducer);