import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// import { ScrollContext } from 'react-router-scroll-4';
import "react-app-polyfill/ie11";

import { PersistGate } from "redux-persist/integration/react";

// import store
import store, { persistor } from "./store";

// import action
import { refreshStore } from "./action";

//import utils
// import { definePolyfills, scrollTop, getToken, removeUserSession, setUserSession } from './utils';
import { definePolyfills, scrollTop } from "./utils";

// import routes
import Router from "./routes";

//Import Context
import { AuthenticationContext } from "../src/components/pages/others/common/context";

export function Root() {
  definePolyfills();
  scrollTop();

  useEffect(() => {
    if (store.getState().demo.current !== 22) {
      store.dispatch(refreshStore(22));
    }
  }, []);
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <AuthenticationContext.Provider value={{ loggedIn, setLoggedIn }}>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<span></span>}>
            <BrowserRouter basename={"/"}>
              <Router />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </AuthenticationContext.Provider>
  );
}
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Root />
  // </React.StrictMode>
);
