import { combineReducers } from 'redux';
import promoProductsReducer from './promoProducts';
import manufacturersReducer from './manufacturers';
import categoriesReducer from './categories';
import branchesReducer from './branches';
import blogReducer from './blog';
import userReducer from './users';
import cmsReducer from './cms';
import cartReducer from './cart';
import productReducer from './products';
import wishReducer from './wishlist';
import demoReducer from './demo';
import compareReducer from './compare';
import filtersReducer from './filter';
import searchReducer from './search';
// import fleetReducer from './cars';

const rootReducer = combineReducers({
  promoProducts: promoProductsReducer,
  manufacturers: manufacturersReducer,
  categories: categoriesReducer,
  branches: branchesReducer,
  products: productReducer,
  blog: blogReducer,
  users: userReducer,
  pageBySlug: cmsReducer,
  cartList: cartReducer,
  data: productReducer,
  wishlist: wishReducer,
  demo: demoReducer,
  compareList: compareReducer,
  filter: filtersReducer,
  search: searchReducer,
});

export default rootReducer;