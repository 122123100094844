import { 
  RECEIVE_PROMO_PRODUCTS, 
  RECEIVE_PROMO_PRODUCTS_HOME, 
  REFRESH_STORE 
} from '../constants/action-types';

const initialState = {
  promoProducts: [],
  promoProductsHome: []
}

const promoProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROMO_PRODUCTS:
      return { ...state, promoProducts: action.promoProducts };

    case RECEIVE_PROMO_PRODUCTS_HOME:
      return { ...state, promoProductsHome: action.promoProductsHome };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
};

export default promoProductsReducer;