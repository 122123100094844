import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST, REFRESH_STORE } from '../constants/action-types';
import { findProductIndex, findProductVariantIndex } from '../utils';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const initialState = { list: [] }

const wishlistReducer = (state = { list: [] }, action) => {
  switch (action.type) {

    case ADD_TO_WISHLIST:
      if (!findProductVariantIndex(state.list, action.product.product_variant_id)) {
        return {
          ...state,
          list: [...state.list, action.product]
        };
      } else if (!findProductIndex(state.list, action.product.product_id)) {
        return {
          ...state,
          list: [...state.list, action.product]
        };
      }
      return state;

    case REMOVE_FROM_WISHLIST:
      return {
        list: state.list.filter(product => product.product_variant_id ? product.product_variant_id !== action.product.product_variant_id : product.product_id !== action.product.product_id)
      };

    case REFRESH_STORE:
      return initialState;

    default:
      return state;
  }
}

const persistConfig = {
  keyPrefix: "buduj-",
  key: "wishlists",
  storage
}

export default persistReducer(persistConfig, wishlistReducer);